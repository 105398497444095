<template>
  <div
    ref="scrollstatesettermlist"
    :class="[
      (folderMedia.length || (folderSection && folderSection.length)) &&
        'fill-height',
      'flex-x flex-wrap align-start q-px-md',
    ]"
  >
    <div v-if="isFolderOpenView" class="flex-1 folder-open-view">
      <folder-detail-list-view
        :isFolderOpenView="isFolderOpenView"
        :folderSection="folderSection"
        :items="folderMedia"
        :fullViewHandler="fullViewHandler"
        :completedMediaPoints="completedMediaPoints"
        :addMediaBookmark="addMediaBookmark"
        :removeMediaBookmark="removeMediaBookmark"
        :attachToPost="attachToPost"
        :fromComputer="fromComputer"
        :downloadFile="downloadFile"
        :openNextTabLink="openNextTabLink"
        :removeMedia="removeMedia"
        :canRemove="canRemove"
        :currentUser="currentUser"
        :currentWorkspace="currentWorkspace"
        :openFolderId="openFolderId"
        :companyId="companyId"
        :activeCompany="activeCompany"
        :closeFolderHandler="closeFolderHandler"
        :folderList="folderList"
        :isWorkspaceOwner="isWorkspaceOwner"
        :authToken="authToken"
        :editDoc="editDoc"
        @setProgress="(progress) => $emit('setProgress', progress)"
      />
    </div>
    <div v-else class="flex-1 media-list-custom-table">
      <div
        v-if="selected.length"
        class="row items-center q-pl-sm stick-at-top"
        style="width: calc(100vw - 130px)"
      >
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.fasShare"
          label="Share"
          class="multiple-select-options primary-btn list-view"
          @click="checkShareFolderAccess(selected)"
        />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.mdiContentCopy"
          @click="
            copyMoveFileFolderHandler(
              selected,
              false,
              true,
              false,
              isFolderOpenView ? false : true
            )
          "
          label="Copy"
          class="multiple-select-options primary-btn list-view"
        />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.mdiCursorMove"
          @click="
            copyMoveFileFolderHandler(
              selected,
              true,
              true,
              false,
              isFolderOpenView ? false : true
            )
          "
          label="Move"
          class="multiple-select-options primary-btn list-view"
        />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.fasTrash"
          label="Delete"
          class="multiple-select-options red-btn list-view"
          @click="bulkDeleteFileConfirm.flag = true"
        />
      </div>
      <table class="an-15 medium-text">
        <thead>
          <tr
            class="an-15 demi-bold-text stick-at-top"
            :style="selected.length && { top: '34px' }"
          >
            <th width="40%">
              <div class="left-radius q-pt-sm">
                <q-item class="q-pa-none">
                  <q-item-section avatar>
                    <q-checkbox
                      v-model="selectAll"
                      size="sm"
                      @input="toggleAll"
                    />
                  </q-item-section>
                  <q-item-section avatar>
                    <div class="greybtn--text">Name</div>
                  </q-item-section>
                </q-item>
              </div>
            </th>
            <th width="20%">
              <div class>
                <q-item>
                  <q-item-section avatar>
                    <q-item-label class="greybtn--text">
                      Comments
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </th>
            <th width="20%">
              <div class>
                <q-item>
                  <q-item-section avatar>
                    <q-item-label class="greybtn--text"> Size </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </th>
            <th width="20%">
              <div class="right-radius">
                <q-item>
                  <q-item-section avatar>
                    <q-item-label class="greybtn--text">
                      Date Uploaded
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!isFolderOpenView">
            <template v-for="folder in folderList">
              <folder
                :folder="folder"
                :key="folder.id + '-list-folder'"
                :deleteFolder="deleteFolderHandler"
                @renameFolder="openRenameFolderDialog"
                :toggleSelect="toggleSelect"
                :selectedIds="selectedIds"
                :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                :attachFolderToPost="attachFolderToPost"
                :addMediaFolderBookmark="addMediaFolderBookmark"
                :removeMediaFolderBookmark="removeMediaFolderBookmark"
                @openfolder="openFolder"
                :uploadFileToFolder="uploadFileToFolder"
              />
            </template>
          </template>
          <template v-for="item in items">
            <tr
              :class="[
                selectedIds.includes(item.id) && 'selected-item',
                'vabotu-tbl-row group-hover',
              ]"
              :key="item.id"
              @click="fullview(item, 'noComment')"
            >
              <q-menu context-menu content-class="overflow-auto">
                <q-list>
                  <q-item clickable>
                    <q-item-section> Share </q-item-section>
                    <q-item-section side>
                      <q-icon :name="$icons.matKeyboardArrowRight" />
                    </q-item-section>
                    <q-menu auto-close anchor="top end" self="top start">
                      <q-list style="min-width: 150px">
                        <q-item @click="attachToPost(item)" clickable>
                          Attach to message
                        </q-item>
                        <q-item
                          v-if="!item.is_document_section"
                          @click.stop="$copyTextToClipboard(item.file)"
                          clickable
                        >
                          <q-item-section> Copy Link </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-item>
                  <q-item
                    clickable
                    @click="
                      copyMoveFileFolderHandler(
                        [item],
                        true,
                        false,
                        false,
                        isFolderOpenView ? false : true
                      )
                    "
                    v-close-popup
                  >
                    <q-item-section> Move </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    class="q-mb-sm"
                    @click="
                      copyMoveFileFolderHandler(
                        [item],
                        false,
                        false,
                        false,
                        isFolderOpenView ? false : true
                      )
                    "
                    v-close-popup
                  >
                    <q-item-section> Copy </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    class="q-mb-sm"
                    @click="openRenameFileDialog(item)"
                    v-close-popup
                  >
                    <q-item-section> Rename </q-item-section>
                  </q-item>
                  <q-item
                    v-if="
                      item.is_document_section &&
                      !item.is_read_only &&
                      currentUser &&
                      currentUser.id === item.user_id
                    "
                    @click="updateReadOnlyHandler(item.id, true)"
                    clickable
                    class="q-mb-sm"
                    v-close-popup
                  >
                    <q-item-section> Set to read only </q-item-section>
                  </q-item>
                  <q-item
                    v-else-if="
                      item.is_document_section &&
                      item.is_read_only &&
                      currentUser &&
                      currentUser.id === item.user_id
                    "
                    @click="updateReadOnlyHandler(item.id, false)"
                    clickable
                    class="q-mb-sm"
                    v-close-popup
                  >
                    <q-item-section> Remove read only </q-item-section>
                  </q-item>
                  <q-separator />
                  <q-item
                    clickable
                    @click="fromComputer(item.id)"
                    v-if="canRemove && item.type.match(/image.*/)"
                    v-close-popup
                  >
                    <q-item-section> Replace image </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="downloadFile(item.id)"
                    v-if="item.file_resource === 's3'"
                    v-close-popup
                  >
                    <q-item-section> Download </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="fullview(item, 'comment')"
                    v-if="item.type.match(/image.*/)"
                    v-close-popup
                  >
                    <q-item-section> Add Comment </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="openNextTabLink(item)"
                    v-if="item.file_resource === 'dropbox'"
                    v-close-popup
                  >
                    <q-item-section> Open Dropbox Link </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="openNextTabLink(item)"
                    v-if="item.file_resource === 'google'"
                    v-close-popup
                  >
                    <q-item-section> Open GoogleDrive Link </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="removeMedia(null, item.id)"
                    class="text-red"
                    v-if="
                      canRemove ||
                      (currentUser && currentUser.id === item.user_id)
                    "
                    v-close-popup
                  >
                    <q-item-section> Delete File </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              <td width="20%">
                <q-list class="list list--two-line vb-tbody-list">
                  <template>
                    <q-item class="q-pl-none">
                      <q-item-section class="items-center" avatar>
                        <span
                          @click.stop="toggleSelect(item)"
                          class="custom-selection-checkbox"
                        >
                          <div
                            class="selected-custom-selection-checkbox"
                            v-if="selectedIds.includes(item.id)"
                          ></div>
                        </span>
                      </q-item-section>
                      <q-item-section avatar>
                        <q-avatar size="40px" style="border-radius: 6px">
                          <img
                            :src="
                              item.file_resource === 'google'
                                ? item.thumb
                                : item.file_resource === 'dropbox' &&
                                  item.type.match(/image.*/)
                                ? item.thumb
                                : item.fileSrc
                            "
                            class="cursor-pointer"
                            style="border-radius: 7px"
                          />
                          <div
                            v-if="
                              item.type.match(/video.*/) &&
                              item.file_resource !== 'dropbox'
                            "
                            class="row justify-center items-center absolute video-icon"
                          >
                            <div class="text-center flex-1">
                              <q-btn
                                :icon="$icons.mdiPlay"
                                color="primary"
                                size="sm"
                                round
                                dense
                                padding="0"
                                flat
                              />
                            </div>
                          </div>
                          <div
                            class="comment-point"
                            v-if="item.commentPoint.length > 0"
                            :style="
                              item.commentPoint.length ==
                              completedMediaPoints(item).length
                                ? `background: #15d89a`
                                : ''
                            "
                          >
                            {{
                              completedMediaPoints(item).length +
                              "/" +
                              item.commentPoint.length
                            }}
                          </div>
                          <div
                            style="top: -8px; left: -9px"
                            v-if="item.file_resource === 'google'"
                            class="showing-file-type-table"
                          >
                            <q-btn dense round color="pink" size="md">
                              <q-icon
                                style="top: 3px; left: 3px"
                                class="absolute"
                                size="13px"
                                color="white"
                                :name="$icons.fabGoogleDrive"
                              />
                            </q-btn>
                          </div>
                          <div
                            style="top: -8px; left: -9px"
                            v-if="item.file_resource === 'dropbox'"
                            class="showing-file-type-table"
                          >
                            <q-btn dense round color="pink" size="md">
                              <q-icon
                                style="top: 4px; left: 4px"
                                class="absolute"
                                size="13px"
                                color="white"
                                :name="$icons.fabDropbox"
                              />
                            </q-btn>
                          </div>
                        </q-avatar>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label
                          class="username break-word pl-3 an-15 medium-text"
                        >
                          {{ item.filename }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </td>
              <td>
                <q-list>
                  <q-item>
                    <q-item-section avatar>
                      <q-item-label>
                        <!-- <q-icon
                            class="comment-icon-media"
                            name="fas fa-comments"
                          /> -->
                        {{
                          item.commentPoint.length
                            ? completedMediaPoints(item).length +
                              "/" +
                              item.commentPoint.length
                            : 0
                        }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </td>
              <td>
                <q-list>
                  <q-item>
                    <q-item-section avatar>
                      <q-item-label>
                        <!-- <q-icon
                            class="comment-icon-media"
                            name="fas fa-comments"
                          /> -->
                        <template v-if="item.is_document_section"> - </template>
                        <template v-else>
                          {{ item.size | filesizeFormat }} MB
                        </template>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </td>
              <td>
                <q-list>
                  <template>
                    <q-item>
                      <q-item-section>
                        <q-item-label>
                          {{ item.created_at | tableDateFormate }}
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <div
                          class="task-menu-option thread-icons row item-center q-px-xs q-mt-sm q-mr-xs group-hover-item"
                          style="z-index: 100"
                        >
                          <div
                            v-if="item.isbookmarked"
                            @click.stop="removeMediaBookmark(item)"
                            class="cursor-pointer"
                          >
                            <q-icon
                              size="20px"
                              color="pink"
                              :name="$icons.matBookmark"
                            >
                              <q-tooltip> Remove from Saved items </q-tooltip>
                            </q-icon>
                          </div>
                          <div
                            v-if="!item.isbookmarked"
                            @click.stop="addMediaBookmark(item)"
                            class="cursor-pointer"
                          >
                            <q-icon
                              size="20px"
                              color="black"
                              :name="$icons.matBookmarkBorder"
                            >
                              <q-tooltip> Add to Saved items </q-tooltip>
                            </q-icon>
                          </div>
                        </div>
                        <div>
                          <q-icon
                            v-if="item.isbookmarked"
                            @click.stop="removeMediaBookmark(item)"
                            size="20px"
                            class="cursor-pointer"
                            color="pink"
                            :name="$icons.matBookmark"
                          >
                            <q-tooltip> Remove from Saved items </q-tooltip>
                          </q-icon>
                        </div>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="full-width q-pr-sm text-center" v-if="isloading">
        <q-circular-progress
          indeterminate
          size="30px"
          :thickness="0.2"
          color="primary"
        />
      </div>
    </div>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="isFolderOpenView ? sectionGalleryMedia : items"
      :ishide="ishide"
      :labels="labels"
      :moreOption="moreOption"
      :currentUser="currentUser"
      :dialogMedia="dialogMedia"
      :isWorkspaceOwner="isWorkspaceOwner"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :resetDialog="() => resetDialog()"
      @sendComment="sendComment"
      @updateComment="updateComment"
      @updatepositions="updatepositions"
      @clickonhidebutton="ishide = !ishide"
      @clickonmoreoption="clickonmoreoption"
      @deleteMediaComment="deleteMediaComment"
      @deleteCommentPoint="deleteCommentPoint"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @setActiveMedia="setActiveMedia"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
    ></image-viewer-dialog>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="items"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :medias="items"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    >
    </audio-dialog>
    <confirm-dialog
      v-if="delete_media.flag"
      v-model="delete_media.flag"
      title="Delete File?"
      question="Are you sure you want to delete this file?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => removeMediaDialogHandler(false)"
      :successMethod="() => removeMediaDialogHandler(true)"
      :loading="deletingMedia"
    />
    <pdf-view-dialog
      v-if="showpdfModel"
      :fileExt="fileExt"
      v-model="showpdfModel"
      @resetPdfDialog="showpdfModel = false"
      :pdfMediaData="pdfMediaData"
    />

    <create-media-folder-dialog
      v-if="renameMediaFolderDialog.flag"
      v-model="renameMediaFolderDialog.flag"
      :renameFolder="renameFolderDetails"
      :loading="renameMediaFolderDialog.loading"
      :createFolderHandler="renameFolderHandler"
      :cancelMethod="cancel"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
    <copy-move-file-folder-dialog
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isMoveAction="copyMoveDialog.isMoveAction"
      :fileFolderDetails="copyMoveDialog.data"
      :isRoot="copyMoveDialog.isRoot"
      :isMultiple="copyMoveDialog.isMultiple"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyFileFolderHandler="moveCopyFileFolderHandler"
      :multipleMoveCopyHandler="multipleMoveCopyHandler"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="folderList"
      :updateFolderCache="updateFolderCache"
      :authToken="authToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <div v-if="bulkDeleteFileConfirm.flag">
      <confirm-dialog
        v-model="bulkDeleteFileConfirm.flag"
        title="Delete Assets?"
        question="Are you sure you want to delete these assets?"
        cancleText="Cancel"
        successText="Yes"
        :cancleMethod="() => bulkDeleteAssetsConfirmHandler(false)"
        :successMethod="() => bulkDeleteAssetsConfirmHandler(true)"
        :loading="bulkDeleteFileConfirm.loader"
      />
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="folderFileUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
    ></vue-dropzone-file-upload>
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
    />
  </div>
</template>

<script>
import orderBy from "lodash/orderBy";
import MediaViewMixin from "@/mixins/MediaViewMixin";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { getDownloadUrl } from "@/services/downloadS3File";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import { SET_WORKSPACE_EDITOR_MEDIA } from "@/store/mutation-types";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import Folder from "@/views/MediaView/ListView/Folder";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";
import CopyMoveFileFolderDialog from "@/components/Dialogs/CopyMoveFileFolderDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import FolderDetailListView from "@/views/MediaView/FolderDetailListView";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";

import {
  RenameMediaFolderMutation,
  DeleteMediaMutation,
  DeleteMediaFolderMutation,
  UpdateMediaMutation,
} from "@/gql";

export default {
  name: "ListView",
  mixins: [MediaViewMixin, FileExtensionMixin],
  props: [
    "workspace",
    "canRemoveMedia",
    "user",
    "currentCompanyId",
    "currentView",
    "isloading",
    "imageList",
    "labels",
    "isWorkspaceOwner",
    "activeCompany",
    "addMediaBookmark",
    "removeMediaBookmark",
    "fromComputer",
    "folderList",
    "isFolderOpenView",
    "openFolderId",
    "authToken",
    "folderSection",
    "closeFolderHandler",
    "editDoc",
    "openDocEditor",
  ],
  components: {
    VideoDialog,
    AudioDialog,
    PdfViewDialog,
    ImageViewerDialog,
    ConfirmDialog,
    Folder,
    CreateMediaFolderDialog,
    CopyMoveFileFolderDialog,
    FolderDetailsDialog,
    VueDropzoneFileUpload,
    FolderDetailListView,
    UpdateFileDialog,
  },
  data() {
    return {
      showVideoModel: false,
      showAudioModel: false,
      displayPlayer: false,
      displayAudioPlayer: false,
      videoMediaData: null,
      audioMediaData: null,
      gridMediaId: "",
      ishide: false,
      hideButtonBack: "#231d3b !important",
      media: [],
      showModal: false,
      dialogMedia: null,
      copylinkurlmedia: "",
      delete_media: {
        id: null,
        flag: null,
      },
      pagination: {
        loading: false,
        limit: 10,
        offset: 0,
      },
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
        // {
        //   title: "Share FIle"
        // },
        // {
        //   title: "Delete File"
        // }
      ],
      mediaListWidth: null,
      pdfMediaData: null,
      showpdfModel: false,
      mediaMenu: false,
      deletingMedia: false,
      renameMediaFolderDialog: {
        flag: false,
        loading: false,
      },
      renameFolderDetails: null,
      deleteFolder: {
        id: null,
        flag: false,
        loader: false,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      folderId: null,
      sectionMedia: [],
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
    };
  },
  methods: {
    async updateReadOnlyHandler(id, flag) {
      try {
        let is_read_only = flag;
        const variables = {
          id: id,
          is_read_only,
        };
        await this.$api.mutate({
          mutation: UpdateMediaMutation,
          skipUpdates: true,
          variables,
        });

        const media = this.$api.getEntity("media", id);
        media.is_read_only = is_read_only;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: flag
            ? "Document added read only mode."
            : "Document remove read only mode.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    checkShareFolderAccess(selected) {
      let eligible = this.$checkEligiblity("share_folders");
      if (eligible) {
        this.attacheFileFolderToMessage(selected);
      }
    },
    openRenameFolderDialog(folder) {
      this.renameFolderDetails = folder;
      this.renameMediaFolderDialog.flag = true;
    },
    cancel() {
      this.renameFolderDetails = null;
      this.renameMediaFolderDialog.flag = false;
      this.renameMediaFolderDialog.loading = false;
    },
    async renameFolderHandler(title) {
      this.renameMediaFolderDialog.loading = true;
      if (this.renameFolderDetails) {
        try {
          const variables = {
            id: this.renameFolderDetails.id,
            title,
          };
          await this.$api.mutate({
            mutation: RenameMediaFolderMutation,
            variables,
          });
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          //cache update
          query.data.mediaFolders = query.data.mediaFolders.map((f) => {
            if (f.id === this.renameFolderDetails.id) {
              f.title = title;
            }
            return f;
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        }
      }
    },
    async deleteFolderHandler(folderId) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            id: this.deleteFolder.id,
          };
          await this.$api.mutate({
            mutation: DeleteMediaFolderMutation,
            variables,
          });
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          query.data.mediaFolders = query.data.mediaFolders.filter(
            (f) => f.id !== this.deleteFolder.id
          );
          // this.$mixpanelEvent("delete-folder", {});
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
    openNextTabLink(media) {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(media.file);
      else window.open(media.file, "_blank");
    },
    attachToPost(media) {
      let value = {};
      const files = this.$store.getters.workspaceEditorMedia(
        this.companyId,
        this.workspace
      );

      if (files) {
        value = files;
      }

      value[media.id] = media;
      this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
        value,
        companyId: this.companyId,
        workspaceId: this.workspace,
      });
      this.$router.push(
        `/companies/${this.activeCompany}/workspaces/${this.workspace}/messaging`
      );
    },
    resetPagination() {
      this.pagination.offset = 0;
      this.pagination.limit = 10;
    },
    fullViewHandler(item, value, sectionMedia) {
      this.sectionMedia = sectionMedia;
      this.fullview(item, value);
    },
    fullview(item, value) {
      if (item.type.match(/image.*/)) {
        if (value === "comment") {
          this.dialogMedia = item;
          this.gridMediaId = item.id;
          this.showModal = true;
          const data = {
            title: "Add Comment",
            gridMediaId: this.gridMediaId,
            forscroll: "no",
            x: 0,
            y: 0,
          };
          this.copylinkurlmedia = item;
          this.clickonmoreoption(data);
        } else {
          this.dialogMedia = item;
          this.gridMediaId = item.id;
          this.showModal = true;
          this.copylinkurlmedia = item;
        }
      } else if (item.type.match(/video.*/) && item.file_resource === "s3") {
        this.videoPlayer(item);
      } else if (item.type.match(/audio.*/) && item.file_resource === "s3") {
        this.audioPlayer(item);
      } else if (
        !item.type.match(/image.*/) &&
        !item.type.match(/video.*/) &&
        !item.type.match(/zip.*/) &&
        item.file_resource === "s3"
      ) {
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(item.file);
        else window.open(item.file, "_blank");
      } else if (item.is_document_section) {
        this.editDoc(item);
      } else {
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(item.file);
        else window.open(item.file, "_blank");
      }
    },
    videoPlayer(media) {
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    async downloadFile(id) {
      this.mediaMenu = false;
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
      this.$emit("closeMediaModel", {
        id: this.gridMediaId,
      });
    },
    removeMedia(postId, mediaId) {
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        //this.$emit("delete", this.delete_media.id);
        this.deletingMedia = true;
        await this.$api.mutate({
          mutation: DeleteMediaMutation,
          variables: { id: this.delete_media.id },
        });

        if (this.openFolderId) {
          const query = this.$api.getQuery(
            `MediaFolderQuery:${this.openFolderId}`
          );
          query.data.mediaFolder.media = query.data.mediaFolder.media.filter(
            (f) => f.id !== this.delete_media.id
          );
        } else {
          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia = query.data.newMedia.filter(
            (f) => f.id !== this.delete_media.id
          );
        }
      }

      this.resetListMediaSelection();
      this.deletingMedia = false;
      this.delete_media.flag = false;
      this.delete_media.id = null;
    },
    scollbar() {
      if (this.imageList.length <= 50) {
        this.$nextTick(() => {
          const ps = this.$refs.scrollstatesettermlist;
          if (ps) ps.scrollTop = 1;
        });
      }
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: this.gridMediaId,
        media: this.copylinkurlmedia,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
    deleteCommentPoint(deletedata) {
      const makedata = {
        id: deletedata.id,
        index: deletedata.index,
        media_id: this.gridMediaId,
      };
      this.$emit("deleteCommentPoint", makedata);
    },
    sendComment(data) {
      const commentArg = {
        media_comments_point_id: data.id,
        comment: data.comment,
        media_id: this.gridMediaId,
      };
      this.$emit("sendComment", commentArg);
    },
    updatepositions(updateposition) {
      const positions = {
        id: updateposition.id,
        x: updateposition.x,
        y: updateposition.y,
        media_id: this.gridMediaId,
      };
      this.$emit("updatepositions", positions);
    },
    updateMediaCommentPointLabel(themedata) {
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    deleteMediaComment(commentData) {
      const commentdata = {
        commentid: commentData.commentid,
        index: commentData.index,
        media_id: this.gridMediaId,
        commentPointId: commentData.commentpointid,
      };
      this.$emit("deleteMediaComment", commentdata);
    },
    updateComment(changedata) {
      this.$emit("updateComment", changedata);
    },
    pdfView(media) {
      this.pdfMediaData = media;
      this.showpdfModel = true;
      // if (this.fileExt.match(/pdf.*/)) {
      //   window.open(media.file, "_self");
      // } else {
      // }
    },
    resetPdfDialog() {
      setTimeout(() => {
        this.showpdfModel = false;
      }, 10);
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        find(
          this.$refs.mediaCommentingView.$refs.commentBox,
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    completedMediaPoints(media) {
      return media.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
  },
  computed: {
    fileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.imageList.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.imageList.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    items() {
      if (this.imageList) {
        this.scollbar();
        // Used Mixin : FileExtensionMixin
        return this.transformMediaCollection(this.imageList);
      } else {
        return [];
      }
    },
    folderMedia() {
      if (this.imageList) {
        // Used Mixin : FileExtensionMixin
        return orderBy(this.transformMediaCollection(this.imageList), [
          "folder_sequence",
        ]);
      } else {
        return [];
      }
    },
    sectionGalleryMedia() {
      if (this.sectionMedia) {
        // Used Mixin : FileExtensionMixin
        return orderBy(this.transformMediaCollection(this.sectionMedia), [
          "folder_sequence",
        ]);
      } else {
        return [];
      }
    },
    currentWorkspace() {
      return this.workspace;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    currentUser() {
      return this.user;
    },
    companyId() {
      return this.currentCompanyId;
    },
  },
  watch: {
    "$route.params.workspace": function () {
      this.resetPagination();
    },
    ishide: {
      handler: function (newValue) {
        if (newValue) {
          this.hideButtonBack = "#8E9DAD !important";
        } else {
          this.hideButtonBack = "#231d3b !important";
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.comment-point {
  position: absolute;
  right: -3px;
  top: -2px;
  width: 25px;
  height: 15px;
  border-radius: 9.5px;
  display: flex;
  color: white;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  z-index: 1;
  background: #ff3279;
}
</style>
