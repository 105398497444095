/* eslint-disable no-mixed-spaces-and-tabs */
import Vue from "vue";
import {
  MoveMediaFolderMutation,
  CopyMediaFolderMutation,
  MoveMediaMutation,
  CopyMediaMutation,
  DeleteBulkMediaMutation,
  DeleteBulkFolderMutation,
  GetNewMediaListMutation,
  MediaFoldersQuery,
  AttachFolderMediaMutation,
  UpdateMediaMutation,
  CreateMediaCardMutation,
  UpdateMediaCardMutation,
  DeleteMediaCardMutation,
  AttachMediaToSectionMutation,
  AddMediaFolderBookmarkMutation,
  RemoveMediaFolderBookmarkMutation,
  MoveMediaCardMutation,
  MediaFolderQuery,
} from "@/gql";
import { SET_WORKSPACE_EDITOR_MEDIA } from "@/store/mutation-types";
// import orderBy from "lodash/orderBy";
import map from "lodash/map";
import filter from "lodash/filter";
import last from "lodash/last";
import first from "lodash/first";
import RandomPosition from "random-position";

export default {
  data() {
    return {
      selected: [],
      // selectedFolders:[],
      copyMoveDialog: {
        data: [],
        isFolder: false,
        isMoveAction: false,
        isRoot: true,
        flag: false,
        isMultiple: false,
        loading: false,
      },
      bulkDeleteFileConfirm: {
        flag: false,
        loader: false,
      },
      editSection: {
        id: null,
        loading: false,
      },
      deleteSection: {
        id: null,
        flag: false,
        loader: false,
        shouldDeleteMedia: false,
      },
      closedExpansionItems: [],
      sharedFileDialog: false,
      progress: 0,
      sectionId: null,
      moveSectionDialog: {
        flag: false,
        currentFolderSection: null,
        selectedFiles: [],
      },
    };
  },
  beforeDestroy() {
    this.$eventBus.$off(
      "resetListMediaSelection",
      this.resetListMediaSelection
    );
    this.$eventBus.$off("addSectionFromHeader", this.addSectionFromHeader);
  },
  beforeCreate() {
    this.$eventBus.$off(
      "resetListMediaSelection",
      this.resetListMediaSelection
    );
    this.$eventBus.$off("addSectionFromHeader", this.addSectionFromHeader);
  },
  created() {
    this.$eventBus.$on("resetListMediaSelection", this.resetListMediaSelection);
    this.$eventBus.$on("addSectionFromHeader", this.addSectionFromHeader);
  },
  mounted() {
    if (this.currentWorkspace) {
      const mediaFoldersQuery = this.$api.getQuery(
        `MediaFoldersQuery:${parseInt(this.currentWorkspace)}`
      );
      if (mediaFoldersQuery.data) {
        mediaFoldersQuery.data.mediaFolders =
          mediaFoldersQuery.data.mediaFolders.map((f) => {
            return {
              ...f,
              selected: false,
            };
          });
      }
    }
  },
  computed: {
    selectAll: {
      get() {
        if (this.isFolderOpenView) {
          return this.selected.length > 0 &&
            this.selected.length !== this.items.length
            ? null
            : this.selected.length
            ? this.selected.length === this.items.length
            : false;
        } else {
          return this.selected.length > 0 &&
            this.selected.length !== this.items.length + this.folderList.length
            ? null
            : this.selected.length ===
                this.items.length + this.folderList.length;
        }
      },
      set() {
        this.selected.length = this.isFolderOpenView
          ? this.items.length
          : this.items.length + this.folderList.length;
      },
    },
    selectedIds() {
      return this.selected.map((s) => s.id);
    },
  },
  methods: {
    async addMediaFolderBookmark(folder) {
      try {
        const variables = {
          media_folder_id: folder.id,
        };
        const mediaFolderQuery = this.$api.getQuery(
          `MediaFoldersQuery:${this.currentWorkspace}`
        );
        mediaFolderQuery.data.mediaFolders =
          mediaFolderQuery.data.mediaFolders.map((f) => {
            if (f.id === folder.id) {
              f.isbookmarked = !f.isbookmarked;
              return f;
            }
            return f;
          });
        await this.$api.mutate({
          mutation: AddMediaFolderBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaFolderBookmark(folder) {
      try {
        const variables = {
          media_folder_id: folder.id,
        };

        const mediaFolderQuery = this.$api.getQuery(
          `MediaFoldersQuery:${this.currentWorkspace}`
        );
        mediaFolderQuery.data.mediaFolders =
          mediaFolderQuery.data.mediaFolders.map((f) => {
            if (f.id === folder.id) {
              f.isbookmarked = !f.isbookmarked;
              return f;
            }
            return f;
          });
        await this.$api.mutate({
          mutation: RemoveMediaFolderBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    uploadFileToSection(sectionId) {
      this.sectionId = sectionId;
      this.$refs.folderSectionUploader.openFileDialog();
    },
    openSharedFileDialog(sectionId) {
      this.sectionId = sectionId;
      this.sharedFileDialog = true;
    },
    sectionFileUploadCloseHandler(fileList) {
      const mediaIds = fileList ? map(fileList, (o) => o.id) : [];
      this.attachMediaToSection({
        media: mediaIds,
      });
      if (this.sharedFileDialog) {
        this.sharedFileDialog = false;
      }
    },
    async attachMediaToSection(args) {
      try {
        const variables = {
          ...args,
          workspace_id: this.currentWorkspace,
          folder_id: this.openFolderId,
          media_card_id: this.sectionId,
        };
        const response = await this.$api.mutate({
          mutation: AttachMediaToSectionMutation,
          variables,
        });
        //Add to multiple uploaded media to cache
        if (response.data.updateMediaWithCard) {
          const { updateMediaWithCard } = response.data;
          updateMediaWithCard.forEach((media) => {
            const mediaFolder = this.$api.getEntity(
              "mediaFolder",
              this.openFolderId
            );
            if (mediaFolder.media) {
              mediaFolder.media.push(media);
            } else {
              mediaFolder.media = [media];
            }
          });
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Files uploaded successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (erorr) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    attachMediaToPost(files, action = "s3", type = null) {
      if (action === "google") {
        //Attach media to folder from shared file
        if (type === "fromFolder") {
          this.makeDriveMediaData(files);
        }
      }
    },
    async makeDriveMediaData(fileList) {
      // For Any drive Files
      const driveData = [];
      const media_urls = filter(
        fileList,
        (a) =>
          a.file_resource !== "s3" &&
          a.file_resource !== "outsourcce" &&
          !a.hasOwnProperty("verifyKey")
      );
      media_urls &&
        media_urls.forEach((element) => {
          const obj = {
            file: element.file,
            filename: element.filename,
            type: element.type,
            file_resource: element.file_resource,
            size: element.size,
            sequence: element.sequence,
          };
          driveData.push(obj);
        });
      this.attachMediaToSection({
        media_urls: driveData,
      });
    },
    addSectionFromHeader() {
      const sequence = this.folderSection.length
        ? RandomPosition.between(
            RandomPosition.first(),
            first(this.folderSection).sequence
          )
        : RandomPosition.between(RandomPosition.first(), RandomPosition.last());
      const sectionObj = {
        media_folder_id: this.openFolderId,
        title: "New Section",
        sequence: sequence,
      };
      this.addSection(sectionObj);
    },
    // collapsibleChangeHandler(value, section) {
    //   this.$store.dispatch("allSectionList", {
    //     flag:value,
    //     id:section.id
    //   });
    // },
    //Move from one section to another or move from root to section
    onDragAddOrUpdateInSection(event, section) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = this.sectionMedia[section.id]
        ? [...this.sectionMedia[section.id]]
        : [];
      // Move media from one section to another section
      if (media.media_card_id) {
        //Move media inside same section
        if (event.from === event.to) tmp.splice(oldIndex, 1);
      }
      tmp.splice(newIndex, 0, media);
      media.media_card_id = section.id;
      media.folder_sequence = RandomPosition.between(
        tmp[newIndex - 1]
          ? tmp[newIndex - 1].folder_sequence
          : RandomPosition.first(),
        tmp[newIndex + 1]
          ? tmp[newIndex + 1].folder_sequence
          : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          folder_sequence: media.folder_sequence,
          media_card_id: section.id,
        },
      });
    },
    //Move to root section from another section or reorder inside root section
    onDragAddOrUpdateInRoot(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.folderRootMedia];
      //Move inside root section
      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, media);
      media.media_card_id = null;
      media.folder_sequence = RandomPosition.between(
        tmp[newIndex - 1]
          ? tmp[newIndex - 1].folder_sequence
          : RandomPosition.first(),
        tmp[newIndex + 1]
          ? tmp[newIndex + 1].folder_sequence
          : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          folder_sequence: media.folder_sequence,
          media_card_id: null,
        },
      });
    },
    sectionSequenceGenerator(section) {
      if (this.folderSection.length === 1) {
        return RandomPosition.between(
          last(this.folderSection).sequence,
          RandomPosition.last()
        );
      } else {
        if (last(this.folderSection).id === section.id) {
          return RandomPosition.between(
            last(this.folderSection).sequence,
            RandomPosition.last()
          );
        } else {
          const currentSectionIndex = this.folderSection.findIndex(
            (a) => a.id === section.id
          );
          return RandomPosition.between(
            section.sequence,
            this.folderSection[currentSectionIndex + 1].sequence
          );
        }
      }
    },
    addSectionHandler(sectionFrom) {
      const sequence = this.sectionSequenceGenerator(sectionFrom);
      const sectionObj = {
        media_folder_id: this.openFolderId,
        title: "New Section",
        sequence: sequence,
      };
      this.addSection(sectionObj);
    },
    //Create section inside folder
    async addSection(section) {
      const fakeSection = {
        __typename: "mediaCard",
        id: Math.round(Math.random() * -1000000),
        title: section.title,
        user_id: this.currentUser.id,
        sequence: section.sequence,
        media_folder_id: section.media_folder_id,
      };
      // 1. get query
      const folderQuery = this.$api.getQuery(
        `MediaFolderQuery:${this.openFolderId}`
      );
      // 2. add fake entity
      folderQuery.data.mediaFolder.mediaCard.unshift(fakeSection);
      //Scroll to top on adding section from root section only
      if (this.folderSection.length === 0) {
        var element = document.getElementById("dialogScroll");
        element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
      // 3. get real entity
      const response = await this.$api.mutate({
        mutation: CreateMediaCardMutation,
        variables: section,
      });
      if (response.data) {
        //Edit title of newly created section
        this.editSection.id = response.data.createMediaCard.id;
        // 4. replace fake entity
        folderQuery.data.mediaFolder.mediaCard =
          folderQuery.data.mediaFolder.mediaCard.map((section) =>
            section === fakeSection ? response.data.createMediaCard : section
          );
      }
    },
    updateSectionTitle(event) {
      const title = event.target.value.trim();
      const variables = {
        id: this.editSection.id,
        title,
      };
      const sectionEntity = this.$api.getEntity(
        "mediaCard",
        this.editSection.id
      );
      sectionEntity.title = title;
      this.updateSection(variables);
    },
    //Section reordering
    onDragEnd(event) {
      const { item, oldIndex, newIndex } = event;
      const section = this.$api.getEntity("mediaCard", item.dataset.id);
      const tmp = [...this.folderSection];

      // move item from oldIndex to newIndex
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);

      section.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      const variables = {
        id: item.dataset.id,
        sequence: section.sequence,
      };
      this.updateSection(variables);
    },
    moveSectionDown(section) {
      const currentSectionIndex = this.folderSection.findIndex(
        (a) => a.id === section.id
      );
      const sectionEntity = this.$api.getEntity("mediaCard", section.id);
      let sequence;
      if (this.folderSection[currentSectionIndex + 2]) {
        sequence = RandomPosition.between(
          this.folderSection[currentSectionIndex + 1].sequence,
          this.folderSection[currentSectionIndex + 2].sequence
        );
      } else if (
        last(this.folderSection).id ===
        this.folderSection[currentSectionIndex + 1].id
      ) {
        sequence = RandomPosition.between(
          this.folderSection[currentSectionIndex + 1].sequence,
          RandomPosition.last()
        );
      }
      sectionEntity.sequence = sequence;
      const variables = {
        id: section.id,
        sequence,
      };
      this.updateSection(variables);
    },
    moveSectionUp(section) {
      const currentSectionIndex = this.folderSection.findIndex(
        (a) => a.id === section.id
      );
      const sectionEntity = this.$api.getEntity("mediaCard", section.id);
      let sequence;
      if (this.folderSection[currentSectionIndex - 2]) {
        sequence = RandomPosition.between(
          this.folderSection[currentSectionIndex - 2].sequence,
          this.folderSection[currentSectionIndex - 1].sequence
        );
      } else if (
        first(this.folderSection).id ===
        this.folderSection[currentSectionIndex - 1].id
      ) {
        sequence = RandomPosition.between(
          RandomPosition.first(),
          this.folderSection[currentSectionIndex - 1].sequence
        );
      }
      sectionEntity.sequence = sequence;
      const variables = {
        id: section.id,
        sequence,
      };
      this.updateSection(variables);
    },
    async updateSection(variables) {
      try {
        await this.$api.mutate({
          mutation: UpdateMediaCardMutation,
          variables,
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
      this.editSection.id = null;
    },
    openDeleteConfirmationDialog(sectionId, shouldDeleteMedia) {
      this.deleteSection.id = sectionId;
      this.deleteSection.flag = true;
      this.deleteSection.shouldDeleteMedia = shouldDeleteMedia;
    },
    deleteSectionHandler() {
      this.deleteSection.loader = true;
      const folderEntity = this.$api.getEntity(
        "mediaFolder",
        this.openFolderId
      );
      const mediaIds = this.sectionMedia[this.deleteSection.id]
        ? this.sectionMedia[this.deleteSection.id].map((media) => media.id)
        : [];
      if (this.deleteSection.shouldDeleteMedia) {
        //Remove section media from cache
        folderEntity.media = folderEntity.media.filter(
          (m) => !mediaIds.includes(m.id)
        );
        //Remove section from cache
        folderEntity.mediaCard = folderEntity.mediaCard.filter(
          (card) => card.id !== this.deleteSection.id
        );
        this._deleteSection({
          id: this.deleteSection.id,
          delete_media: true,
        });
      } else {
        //Add media to root section when user choose not to delete media inside section
        folderEntity.media = folderEntity.media.map((m) => {
          if (mediaIds.includes(m.id)) {
            m.media_card_id = null;
          }
          return m;
        });
        //Remove section from cache
        folderEntity.mediaCard = folderEntity.mediaCard.filter(
          (card) => card.id !== this.deleteSection.id
        );
        this._deleteSection({
          id: this.deleteSection.id,
          delete_media: false,
        });
      }
      this.$mixpanelEvent("delete-section", {});
    },
    async _deleteSection(variables) {
      try {
        await this.$api.mutate({
          mutation: DeleteMediaCardMutation,
          variables,
        });
        this.resetConfirmationDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.resetConfirmationDialog();
      }
    },
    resetConfirmationDialog() {
      this.deleteSection.id = null;
      this.deleteSection.flag = false;
      this.deleteSection.loader = false;
      this.deleteSection.shouldDeleteMedia = false;
    },
    uploadFileToFolder(folderId) {
      this.folderId = folderId;
      this.$refs.folderFileUploader.openFileDialog();
    },
    async fileUploadCloseHandler(fileList) {
      // if (this.folderId) {
      const mediaIds = fileList ? map(fileList, (o) => o.id) : [];
      this.attachMediasToFolder({ mediaIds, folder_id: this.folderId });
      // }
    },
    async attachMediasToFolder(args) {
      try {
        const variables = {
          media: args.mediaIds,
          folder_id: args.folder_id,
          workspace_id: this.currentWorkspace,
        };
        await this.$api.mutate({
          mutation: AttachFolderMediaMutation,
          variables,
        });
        this.increaseFolderMediaCount({ id: args.folder_id }, args.mediaIds);
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Files uploaded successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    resetListMediaSelection() {
      this.selected = [];
    },
    toggleAll(value) {
      if (!value) {
        this.selected = [];
        if (!this.isFolderOpenView) {
          this.folderList.forEach((element) => {
            element.selected = false;
          });
        }
        this.items.forEach((element) => {
          element.selected = false;
        });
      } else {
        if (!this.isFolderOpenView) {
          this.folderList.forEach((element) => {
            element.selected = true;
          });
          this.selected = [...this.items, ...this.folderList].slice();
        } else {
          this.selected = [...this.items].slice();
        }
        this.items.forEach((element) => {
          element.selected = true;
        });
      }
    },
    toggleFolderSelect(item) {
      if (item.selected) {
        this.selected.push(item);
      } else {
        this.selected.splice(this.selected.indexOf(item), 1);
      }
    },
    toggleSelect(item) {
      item.selected = !item.selected;
      if (item.selected) {
        this.selected.push(item);
      } else {
        this.selected.splice(this.selected.indexOf(item), 1);
      }
    },
    copyMoveFileFolderHandler(
      items,
      isMoveAction = false,
      isMultiple = false,
      isFolder = false,
      isRoot = true
    ) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
      this.copyMoveDialog.isMultiple = isMultiple;
      if (isMoveAction === "move") {
        this.copyMoveDialog.isMoveAction = isMoveAction;
      } else {
        this.copyMoveDialog.isMoveAction = isMoveAction;
      }
    },
    closeCopyMoveFileFolderDialog() {
      this.copyMoveDialog.data = [];
      this.copyMoveDialog.flag = false;
      this.copyMoveDialog.loading = false;
      this.copyMoveDialog.isFolder = false;
      this.copyMoveDialog.isMoveAction = false;
    },
    openMoveFileSectionFolderDialog(section, files) {
      this.moveSectionDialog.currentFolderSection = section;
      this.moveSectionDialog.selectedFiles = files;
      this.moveSectionDialog.flag = true;
    },
    closeMoveFileSectionFolderDialog() {
      this.moveSectionDialog.currentFolderSection = null;
      this.moveSectionDialog.flag = false;
      this.moveSectionDialog.selectedFiles = [];
    },
    async moveFolderSectionHandler(section) {
      try {
        this.copyMoveDialog.loading = true;
        let files = this.moveSectionDialog.selectedFiles;
        const fileIds = files.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          media_card_id: section ? section.id : null,
        };
        const response = await this.$api.mutate({
          mutation: MoveMediaCardMutation,
          variables,
        });
        if (response.data) {
          const query = this.$api.getQuery(
            `MediaFolderQuery:${this.openFolderId}`
          );
          query.data.mediaFolder.media = query.data.mediaFolder.media.filter(
            (file) => {
              if (fileIds.includes(file.id)) {
                file.media_card_id = section ? section.id : null;
              }
              return file;
            }
          );
          this.selected = [];
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) moved successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeMoveFileSectionFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeMoveFileSectionFolderDialog();
      }
    },
    moveCopyFileFolderHandler(
      fileFolderDetails,
      isMoveAction,
      isFolder,
      isRoot,
      workspace,
      folder
    ) {
      if (isFolder) {
        if (isMoveAction) {
          this.moveFolders(fileFolderDetails, workspace);
        } else {
          this.copyFolders(fileFolderDetails, workspace);
        }
      } else {
        if (isMoveAction) {
          this.moveFiles(fileFolderDetails, isRoot, workspace, folder);
        } else {
          this.copyFiles(fileFolderDetails, isRoot, workspace, folder);
        }
      }
    },
    multipleMoveCopyHandler(
      fileFolderDetails,
      isMoveAction,
      isRoot,
      workspace,
      folder
    ) {
      const selectedFolders = fileFolderDetails.filter((o) => {
        if (!o.hasOwnProperty("filename")) {
          return o;
        }
      });
      const selectedFiles = fileFolderDetails.filter((o) => {
        if (o.hasOwnProperty("filename")) {
          return o;
        }
      });
      if (isMoveAction) {
        if (selectedFolders.length) {
          this.moveFolders(selectedFolders, workspace);
        }
        if (selectedFiles.length) {
          this.moveFiles(selectedFiles, isRoot, workspace, folder);
        }
      } else {
        if (selectedFolders.length) {
          this.copyFolders(selectedFolders, workspace);
        }
        if (selectedFiles.length) {
          this.copyFiles(selectedFiles, isRoot, workspace, folder);
        }
      }
      this.selected = [];
    },
    async moveFolders(folders, workspace) {
      try {
        this.copyMoveDialog.loading = true;
        const folderIds = folders.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        const response = await this.$api.mutate({
          mutation: MoveMediaFolderMutation,
          variables,
        });
        if (response) {
          this.folderList = this.folderList.filter(
            (f) => !folderIds.includes(f.id)
          );
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          query.data.mediaFolders = query.data.mediaFolders.filter(
            (f) => !folderIds.includes(f.id)
          );
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) moved successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async copyFolders(folders, workspace) {
      try {
        this.copyMoveDialog.loading = true;
        const folderIds = folders.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        await this.$api.mutate({
          mutation: CopyMediaFolderMutation,
          variables,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async moveFiles(files, isRoot, workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = files.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: MoveMediaMutation,
          variables,
        });
        if (response.data) {
          if (isRoot) {
            this.moveToFolderCacheUpdate(fileIds, folder, workspace);
          } else {
            this.moveFromFolderCacheUpdate(fileIds, folder, workspace);
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) moved successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    moveToFolderCacheUpdate(fileIds, folder, workspace) {
      //increase folder count when moved to folder
      if (workspace.id === this.currentWorkspace) {
        this.increaseFolderMediaCount(folder, fileIds);
      }
      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      query.data.newMedia = query.data.newMedia.filter(
        (f) => !fileIds.includes(f.id)
      );
    },
    async moveFromFolderCacheUpdate(fileIds, folder, workspace) {
      if (workspace.id === this.currentWorkspace) {
        //Moved to another folder of same workspace
        if (folder) {
          this.increaseFolderMediaCount(folder, fileIds);
        } else {
          //Move to root of same folder
          const response = await this.$api.mutate({
            mutation: GetNewMediaListMutation,
            variables: {
              ids: fileIds,
            },
          });

          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia.unshift(...response.data.getNewMediaList);
        }
      }
      const query = this.$api.getQuery(`MediaFolderQuery:${this.openFolderId}`);
      query.data.mediaFolder.media = query.data.mediaFolder.media.filter(
        (file) => !fileIds.includes(file.id)
      );
    },
    increaseFolderMediaCount(folder, fileIds) {
      const query = this.$api.getQuery(
        `MediaFoldersQuery:${this.currentWorkspace}`
      );
      query.data.mediaFolders = query.data.mediaFolders.map((f) => {
        if (f.id === folder.id) {
          f.media_count += fileIds.length;
        }
        return f;
      });
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
      this.updateFolderCache();
    },
    async updateFolderCache() {
      const variables = {
        workspace_id: parseInt(this.currentWorkspace),
      };
      await this.$api.query({
        query: MediaFoldersQuery,
        cacheKey() {
          return `MediaFoldersQuery:${parseInt(this.currentWorkspace)}`;
        },
        variables,
      });
    },
    async copyFiles(files, isRoot, workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = files.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: CopyMediaMutation,
          variables,
        });
        if (response.data) {
          //increase folder count when moved to folder
          if (workspace.id === this.currentWorkspace) {
            //cache update
            const query = this.$api.getQuery(
              `MediaFoldersQuery:${this.currentWorkspace}`
            );

            if (isRoot) {
              query.data.mediaFolders = query.data.mediaFolders.map((f) => {
                if (f.id === folder.id) {
                  f.media_count += fileIds.length;
                }
                return f;
              });
            } else if (
              folder &&
              files[0].media_folder_id &&
              folder.id === files[0].media_folder_id
            ) {
              const query = this.$api.getQuery(`MediaFolderQuery:${folder.id}`);
              query.data.mediaFolder.media.push(
                ...response.data.createDuplicateMedia
              );
            } else if (folder && files.length > 0 && files[0].media_folder_id) {
              let query = this.$api.getQuery(`MediaFolderQuery:${folder.id}`);
              if (!query.data) {
                query = await this.$api.query({
                  query: MediaFolderQuery,
                  variables: {
                    folder_id: folder.id,
                  },
                });
              }
              query.data.mediaFolder.media.push(
                ...response.data.createDuplicateMedia
              );
            } else {
              const query = this.$api.getQuery(
                `MediaQuery:${this.currentWorkspace}`
              );
              query.data.newMedia.unshift(
                ...response.data.createDuplicateMedia
              );
            }
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        console.error("ERROR: MediaViewMixin -> copyFil", error);
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async bulkDeleteAssetsConfirmHandler(flag) {
      if (flag) {
        this.bulkDeleteFileConfirm.loader = true;
        const selectedFolders = this.selected
          .filter((o) => {
            if (!o.hasOwnProperty("filename")) {
              return o;
            }
          })
          .map((f) => f.id);
        const selectedFiles = this.selected
          .filter((o) => {
            if (o.hasOwnProperty("filename")) {
              return o;
            }
          })
          .map((f) => f.id);
        if (selectedFolders.length) {
          this.bulkDeleteFolders(selectedFolders);
        }
        if (selectedFiles.length) {
          this.bulkDeleteFiles(selectedFiles);
        }
      } else {
        this.bulkDeleteFileConfirm.flag = false;
      }
      this.selected = [];
    },
    async bulkDeleteFolders(folderIds) {
      try {
        const variables = {
          folder_id: folderIds,
        };
        await this.$api.mutate({
          mutation: DeleteBulkFolderMutation,
          variables,
        });
        const query = this.$api.getQuery(
          `MediaFoldersQuery:${this.currentWorkspace}`
        );
        query.data.mediaFolders = query.data.mediaFolders.filter(
          (f) => !folderIds.includes(f.id)
        );
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
      this.bulkDeleteFileConfirm.loader = false;
      this.bulkDeleteFileConfirm.flag = false;
    },
    async bulkDeleteFiles(fileIds) {
      try {
        const variables = {
          ids: fileIds,
        };
        await this.$api.mutate({
          mutation: DeleteBulkMediaMutation,
          variables,
        });
        if (this.openFolderId) {
          const query = this.$api.getQuery(
            `MediaFolderQuery:${this.openFolderId}`
          );
          query.data.mediaFolder.media = query.data.mediaFolder.media.filter(
            (file) => !fileIds.includes(file.id)
          );
        } else {
          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia = query.data.newMedia.filter(
            (f) => !fileIds.includes(f.id)
          );
        }
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
      this.bulkDeleteFileConfirm.loader = false;
      this.bulkDeleteFileConfirm.flag = false;
    },
    attacheFileFolderToMessage(items) {
      const selectedFolders = items.filter((o) => {
        if (!o.hasOwnProperty("filename")) {
          return o;
        }
      });
      const selectedFiles = items.filter((o) => {
        if (o.hasOwnProperty("filename")) {
          return o;
        }
      });
      if (selectedFolders.length) {
        this.attachFolderToPost(selectedFolders);
      }
      if (selectedFiles.length) {
        this.attachFilesToPost(selectedFiles);
      }
    },
    attachFolderToPost(folders) {
      let value = {};
      const allFolders = this.$store.getters.workspaceEditorFolders(
        this.companyId,
        this.currentWorkspace
      );

      if (allFolders) {
        value = allFolders;
      }
      folders.forEach((f) => {
        value[f.id] = f;
      });
      this.$store.dispatch("setWorkspaceEditorFolder", {
        value,
        companyId: this.companyId,
        workspaceId: this.currentWorkspace,
      });
      this.$router.push(
        `/companies/${this.activeCompany}/workspaces/${this.currentWorkspace}/messaging`
      );
    },
    attachFilesToPost(files) {
      let value = {};
      const allFiles = this.$store.getters.workspaceEditorMedia(
        this.companyId,
        this.currentWorkspace
      );
      if (allFiles) {
        value = allFiles;
      }
      files.forEach((f) => {
        f["verifyKey"] = "fromShared";
        Vue.set(value, f.id, f);
      });
      this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
        value,
        companyId: this.companyId,
        workspaceId: this.currentWorkspace,
      });
      if (this.$route.name !== "MessagingView") {
        this.$router.push(
          `/companies/${this.activeCompany}/workspaces/${this.currentWorkspace}/messaging`
        );
      } else {
        this.closeFolderHandler();
      }
    },
  },
};
